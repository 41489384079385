import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { API } from "../config";

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

export default function Admin({ validChain }) {
  const [address, setAddress] = useState('');

  async function approveUser() {
    if (address === undefined || address.length !== 42 || address.substring(0,2) !== "0x") {
      toast.error("The given value is not a valid Ethereum address");
      return;
    }

    let url = API.URI+'/admin/approveUser';
    let data = {
      walletAddress: address,
      APIKey: API.KEY
    }

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (!response.ok) {
      toast.error("Request failed - "+result.msg);
      return;
    }

    toast(result.msg);

  }
  
  async function generateUserProof() {
    if (address === undefined || address.length !== 42 || address.substring(0,2) !== "0x") {
      toast.error("The given value is not a valid Ethereum address");
      return;
    }

    let url = API.URI+'/admin/generateProof';
    let data = {
      walletAddress: address,
      APIKey: API.KEY
    }

    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (!response.ok) {
      toast.error("Request failed - "+result.msg);
      return;
    }

    toast(result.msg);

  }

  

  async function fetchUserStatus() {
    if (address === undefined || address.length !== 42 || address.substring(0,2) !== "0x") {
      toast.error("The given value is not a valid Ethereum address");
      return;
    }

    toast("API calls not implemented yet");

  }

  return (
    <>
      <Navbar validChain={validChain} />

      <div className="content-wrapper">
        <h1>Admin management</h1>
        <br/>

        <input type="text" className="input-field" value={address} placeholder="ETH address" onChange={(e) => setAddress(e.target.value)}></input>
        <br/>
        <button className="btn-primary" onClick={approveUser} disabled={true}>Start enrollment</button>
        <button className="btn-primary" onClick={generateUserProof} disabled={true}>Generate proof</button>

        <br/>

        <p>
          <b>Start enrollment</b> - Simulates the user starting the enrollment process<br/>
          <b>Generate proof</b> - Simulates the enrollment process completing successfully
        </p>

      </div>

      <Footer />
    </>
  );
}