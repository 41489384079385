import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useAccount, useNetwork, useBalance, useWaitForTransaction, useContractRead, useContractWrite, useSignTypedData, useSwitchNetwork } from 'wagmi';
import { mainnet, goerli, polygon, polygonMumbai, bsc } from 'wagmi/chains';
import { Web3Button } from "@web3modal/react";
import BigNumber from 'bignumber.js';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import Persona from 'persona';

import { blastTest, blast, API, ADDRESSES, ABIS, NETWORKS, NETWORKSBYID, NETWORKCODENAMES, NETWORKURLS } from "../config";

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { SwitchNetwork } from '../components/SwitchNetwork';
import AccountInfo from '../components/AccountInfo';
import WaitingScreen from '../components/WaitingScreen';
import SuccessScreen from '../components/SuccessScreen';

import ellipse from '../img/ellipse.svg';
import lines from '../img/bg-lines.svg';

import arrow from '../img/arrow.svg';
import tick from '../img/tick.svg';
import lock from '../img/lock.svg';
import gear from '../img/gear.svg';
import mintedTick from '../img/minted-tick.svg';
import ethFull from '../img/logos/eth-full.svg';
import polygonFull from '../img/logos/polygon-full.svg';
import blastFull from '../img/logos/blast-full.svg';

const fullLogos = {
  [mainnet.id]: ethFull,
  [goerli.id]: ethFull,
  [polygon.id]: polygonFull,
  [blastTest.id]: blastFull
};

const fullNames = {
  [mainnet.id]: "ETH Mainnet",
  [goerli.id]: "Goerli Testnet",
  [polygon.id]: "Polygon Mainnet",
  [blastTest.id]: "Blast Sepolia"
};


import logoPolygon from '../img/logos/polygon.svg';
import logoEth from '../img/logos/eth.svg';
import logoBsc from '../img/logos/bsc.svg';
import logoBlast from '../img/logos/blast.svg';
import WarningScreen from '../components/WarningScreen';

let personaClient;

const domain = {
  name: 'Demos',
};

const types = {
  Message: [
    { name: 'message', type: 'string' },
    { name: 'userAddress', type: 'address' },
  ],
};

const authMessage = "I hereby agree to use this address for my biometric enrollment at Demos";

BigNumber.config({ EXPONENTIAL_AT: 1e+9 });

function secondsToTimeString(seconds) {

  var days = Math.floor(seconds / (1000 * 60 * 60 * 24));
  var hours = Math.floor((seconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((seconds % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((seconds % (1000 * 60)) / 1000);


  let timeString = "";

  if (days > 0) {
    timeString += days + " days ";
  }
  if (hours > 0) {
    timeString += hours + " hours ";
  }
  if (minutes > 0) {
    timeString += minutes + " minutes ";
  }
  if (seconds > 0) {
    timeString += seconds + " seconds";
  }

  return timeString;
}

export default function Dashboard({ validChains, requiredChain }) {

  const [allButtonsDisabled, setAllButtonsDisabled] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [isMinting, setIsMinting] = useState(false);
  const [isBurning, setIsBurning] = useState(false);
  const [isUpdatingVerify, setIsUpdatingVerify] = useState(false);
  const [successScreenActive, setSuccessScreenActive] = useState(false);
  const [warningScreenActive, setWarningScreenActive] = useState(false);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);

  const [POETotalSupply, setPOETotalSupply] = useState(null);
  const [isUserHolder, setIsUserHolder] = useState(null);
  const [isUserCurrentNetworkHolder, setIsUserCurrentNetworkHolder] = useState(null);
  const [isUserOnboarded, setIsUserOnboarded] = useState(null);
  const [personaStatus, setPersonaStatus] = useState(null);
  const [personaInquiry, setPersonaInquiry] = useState(null);
  const [isDuplicate, setIsDuplicate] = useState(null);
  const [isBurned, setIsBurned] = useState(null);
  const [isMessageSigned, setIsMessageSigned] = useState(null);
  const [lastVerified, setLastVerified] = useState(null);
  const [lastVerifiedDifference, setLastVerifiedDifference] = useState(null);
  const [signedMessage, setSignedMessage] = useState("");
  const [createdAt, setCreatedAt] = useState(null);
  const [minEnrollTime, setMinEnrollTime] = useState(null);
  const [prices, setPrices] = useState(null);
  const [unenrollAllowedTimestamp, setUnenrollAllowedTimestamp] = useState(0);
  const [minEnrollTimeInWords, setMinEnrollTimeInWords] = useState("?");

  const [verificationDetails, setVerificationDetails] = useState({ signature: "", timestamp: "" });

  const [isRedacted, setIsRedacted] = useState({ "isRedacted": null, "redactedAt": null });
  const [signatureFailed, setSignatureFailed] = useState(false);

  const [isVerifying, setIsVerifying] = useState(false);
  const [isVerified, setIsVerified] = useState({ verified: false, inq: null });

  const [enrollmentButtonActive, setEnrollmentButtonActive] = useState(true);

  const navigate = useNavigate();

  const { address } = useAccount();
  let isWalletConnected = (address !== null && typeof (address) !== 'undefined');

  const { chain } = useNetwork();
  let isNetworkCorrect = validChains?.includes(chain?.id);

  const { switchNetwork } = useSwitchNetwork();

  const currentDisplayNetwork = requiredChain === undefined ? chain?.id : requiredChain;
  const [currentNetwork, setCurrentNetwork] = useState(currentDisplayNetwork);
  //const [currentNetwork, setCurrentNetwork] = useState(chain?.id);

  const [cookies, setCookie] = useCookies(['cookies']);

  const useReadBalance = useBalance({
    address: address,
    enabled: currentNetwork === chain?.id && address !== null && typeof (address) !== 'undefined',
    unit: 'wei',
  })

  const useReadTotalSupply = useContractRead({
    address: ADDRESSES[currentNetwork]?.MeID,
    abi: ABIS.MeID,
    functionName: "totalSupply",
    enabled: currentNetwork === chain?.id && address !== null && typeof (address) !== 'undefined',
    watch: false,
  });

  const useReadUserBalance = useContractRead({
    address: ADDRESSES[currentNetwork]?.MeID,
    abi: ABIS.MeID,
    functionName: "balanceOf",
    enabled: currentNetwork === chain?.id && address !== null && typeof (address) !== 'undefined',
    args: [address],
    watch: true,
  });

  const useReadLastVerified = useContractRead({
    address: ADDRESSES[currentNetwork]?.MeID,
    abi: ABIS.MeID,
    functionName: "lastVerified",
    enabled: currentNetwork === chain?.id && address !== null && typeof (address) !== 'undefined',
    args: [address],
    watch: true,
  });

  const useReadMintTime = useContractRead({
    address: ADDRESSES[currentNetwork]?.MeID,
    abi: ABIS.MeID,
    functionName: "mintTime",
    enabled: currentNetwork === chain?.id && address !== null && typeof (address) !== 'undefined',
    args: [address],
    watch: true,
  });

  const useReadMintPrice = useContractRead({
    address: ADDRESSES[currentNetwork]?.MeID,
    abi: ABIS.MeID,
    functionName: "mintPrice",
    enabled: currentNetwork === chain?.id && address !== null && typeof (address) !== 'undefined',
    watch: false,
  });

  const useReadVerifyPrice = useContractRead({
    address: ADDRESSES[currentNetwork]?.MeID,
    abi: ABIS.MeID,
    functionName: "verifyPrice",
    enabled: currentNetwork === chain?.id && address !== null && typeof (address) !== 'undefined',
    watch: false,
  });

  const useReadMinEnrollTime = useContractRead({
    address: ADDRESSES[currentNetwork]?.MeID,
    abi: ABIS.MeID,
    functionName: "minEnrollTime",
    enabled: currentNetwork === chain?.id && address !== null && typeof (address) !== 'undefined',
    watch: false,
  });


  const useWriteMint = useContractWrite({
    address: ADDRESSES[currentNetwork]?.MeID,
    abi: ABIS.MeID,
    functionName: "userMint",
    value: prices?.mintPrice,
    onError(error) {
      console.log('Error', error)
      toast.error("Transaction failed - try again");
      setIsMinting(false);
      setAllButtonsDisabled(false);
    },
    onSuccess(data) {
      console.log('tx submitted', data);
      toast("Transaction pending...");
      const txResult = waitForMint;
      console.log(txResult);
    },
  });

  const waitForMint = useWaitForTransaction({
    hash: useWriteMint.data?.hash,
    onSettled(data, error) {
      setIsMinting(false);
      setAllButtonsDisabled(false);
      if (!error) {
        toast.success("Minted successfully");
        console.log('Minted successfully', data);
        setSuccessScreenActive(true);
      }
    }
  });

  const useWriteBurn = useContractWrite({
    address: ADDRESSES[currentNetwork]?.MeID,
    abi: ABIS.MeID,
    functionName: "userBurn",
    onError(error) {
      console.log('Error', error)
      toast.error("Transaction failed - try again");
      setIsBurning(false);
      setAllButtonsDisabled(false);
    },
    onSuccess(data) {
      console.log('tx submitted', data);
      toast("Transaction pending...");
      const txResult = waitForBurn;
      console.log(txResult);
    },
  });

  const waitForBurn = useWaitForTransaction({
    hash: useWriteBurn.data?.hash,
    onSettled(data, error) {
      setIsBurning(false);
      setAllButtonsDisabled(false);
      if (!error) {
        setWarningScreenActive(false);
        toast.success("Burned successfully");
        console.log('Burned successfully', data);
      }
    }
  });

  const useWriteVerify = useContractWrite({
    address: ADDRESSES[currentNetwork]?.MeID,
    abi: ABIS.MeID,
    functionName: "userVerify",
    value: prices?.verifyPrice,
    onError(error) {
      console.log('Error', error)
      toast.error("Transaction failed - try again");
      setIsUpdatingVerify(false);
      setAllButtonsDisabled(false);
    },
    onSuccess(data) {
      console.log('tx submitted', data);
      toast("Transaction pending...");
      const txResult = waitForVerify;
      console.log(txResult);
    },
  });

  const waitForVerify = useWaitForTransaction({
    hash: useWriteVerify.data?.hash,
    onSettled(data, error) {
      setIsUpdatingVerify(false);
      setAllButtonsDisabled(false);
      if (!error) {
        setWarningScreenActive(false);
        toast.success("Verified successfully");
        console.log('Verified successfully', data);
      }
    }
  });

  const signAuthenticate = useSignTypedData({
    domain,
    types,
    primaryType: 'Message',
    message: { message: authMessage, userAddress: address },
    onSettled(data, error) {
      if (!error) {
        console.log(data);
        setSignedMessage(data);
      } else {
        console.error(error);
        toast.error("Signature request rejected");
        setSignatureFailed(true);
      }
    },
  });


  async function updateChain(newChain) {
    if (newChain === undefined) return;

    let data = {
      walletAddress: address,
      APIKey: API.KEY
    }

    let params = new URLSearchParams(data).toString();
    let url = API.URI + '/user/balance?' + params;

    try {
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (!response.ok || !result.data) {
        toast.error("Request failed - " + result.msg);
        return;
      }
      console.log(result);

      let isHolder = result.data.balance > 0;
      setIsUserHolder(isHolder);

    } catch (e) {
      toast.error("Request failed - " + e);
      return;
    }

    setCurrentNetwork(newChain.id);
  }

  /*
  useEffect(() => {
    console.log("switching network to connected chain");
    updateChain(chain);
  }, [chain, useReadUserBalance.data]);
  */

  async function switchToRequiredChain() {
    setIsLoading(true);
    await updateChain(NETWORKSBYID[requiredChain]);
    setIsLoading(false);
    //navigate(NETWORKURLS[requiredChain]);
  }

  useEffect(() => {
    console.log("requiredChain", requiredChain);
    console.log("chain", chain);
    if (chain === undefined) return;
    if (requiredChain === undefined) {//TODO
      navigate(NETWORKURLS[chain?.id]);
    } else {
      switchToRequiredChain();
    }
  }, [requiredChain, chain]);



  async function createUser() {
    signAuthenticate.signTypedData();
  }

  async function completeAccountCreation(signedMessageHash) {
    if (signedMessageHash !== "") {

      let url = API.URI + '/enrollment/createUser';
      let data = {
        walletAddress: address,
        signedMessage: signedMessageHash,
        APIKey: API.KEY
      }

      try {
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          console.error("Account creation failed. Try again later.");
          toast.error("Account creation failed. Try again later.");
          return;
        } else {
          console.log("User account has been successfully created.");
          toast.success("User account has been successfully created.");
          fetchUserInfo(false);
          return;
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    }
  }

  useEffect(() => {
    completeAccountCreation(signedMessage);
  }, [signedMessage]);

  async function checkAndRemove() {
    setAllButtonsDisabled(true);
    toast("Your account data is being purged. This may take up to 1 minute");

    let data = {
      walletAddress: address,
      network: NETWORKCODENAMES[currentNetwork],
      APIKey: API.KEY
    }

    let url = API.URI + '/enrollment/checkAndRemoveUser';

    try {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      setAllButtonsDisabled(false);

      if (!response.ok) {
        toast.error("Request failed - " + result.msg);
        return;
      } else {
        toast.success("Your account has been purged successfully.");
        loopFetchUser(false);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function fetchUserInfo(displayEnrollmentSuccess) {
    console.log('fetchUserInfo');

    if (useReadUserBalance.data === undefined) return;
    if (useReadTotalSupply.data === undefined) return;


    console.log("useReadUserBalance.data", useReadUserBalance.data);
    console.log("useReadTotalSupply.data", useReadTotalSupply.data);

    //let isHolder = BigNumber(useReadUserBalance.data.toString()).isGreaterThanOrEqualTo(1);
    let isCurrentNetworkHolder = BigNumber(useReadUserBalance.data.toString()).isGreaterThanOrEqualTo(1);
    let totalSupply = useReadTotalSupply.data.toString();


    let data = {
      walletAddress: address,
      network: NETWORKCODENAMES[currentNetwork],
      APIKey: API.KEY
    }

    let params = new URLSearchParams(data).toString();
    let url = API.URI + '/enrollment/status?' + params;

    try {
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (!response.ok) {
        toast.error("Request failed - " + result.msg);
        return;
      }
      console.log(result);

      if (!result.data?.exists) {
        console.log("User doesn't exist - creating");
        await createUser();
        return;

      } else {
        let isOnboarded = false;
        if (result.data.personaStatus?.toLowerCase() === "not onboarded") {
          console.log("not onboarded");
          if (result.data.isDuplicate) {
            toast.error("Enrollment failed - duplicate biometrics detected");
            setIsDuplicate(true);
          } else {
            setIsDuplicate(false);
          }
        } else if (result.data.personaStatus?.toLowerCase() === "onboarded") {
          isOnboarded = true;
          console.log("Enrollment successful");
          if (displayEnrollmentSuccess) {
            toast.success("Enrollment successful!");
          }
          setIsDuplicate(false);

        } else {
          console.error("unknown status");
        }

        let lastVerifiedDate = null;
        if (result.data.lastVerified !== null) {
          lastVerifiedDate = result.data.lastVerified.slice(0, 19).replace('T', ' ') + " UTC";
        } else {
          lastVerifiedDate = "Never verified";
        }

        //setIsUserHolder(isHolder);
        setIsUserCurrentNetworkHolder(isCurrentNetworkHolder);

        setPOETotalSupply(totalSupply);
        setIsUserOnboarded(isOnboarded);
        setPersonaStatus(result.data.personaStatus);
        setIsBurned(result.data.isBurned);
        setIsMessageSigned(result.data.isMessageSigned);
        setIsRedacted({ "isRedacted": result.data.isRedacted, "redactedAt": result.data.redactedAt.slice(0, 19).replace('T', ' ') });
        setVerificationDetails({ signature: result.data.verificationSignature, timestamp: result.data.verificationTimestamp });
        //setLastVerified(lastVerifiedDate);
        setAllButtonsDisabled(false);
        return result.data.personaStatus;
      }

    } catch (e) {
      toast.error("Request failed - " + e);
      return;
    }
  }

  //loops fetchUser until user status changes
  function loopFetchUser(displayEnrollmentSuccess) {
    console.log("loopFetchUser", personaStatus);
    let initialStatus = personaStatus;
    let intervalCounter = 0;
    setInterval(async function () {
      if (intervalCounter < 15) {
        let hasChanged = false;

        let _personaStatus = await fetchUserInfo(displayEnrollmentSuccess);
        //console.log(intervalCounter, initialStatus, _personaStatus);
        //console.log("isFinished", _personaStatus);
        if (initialStatus === _personaStatus) {
          hasChanged = false;
        } else {
          console.log("[loopFetchUser] status change detected");
          hasChanged = true;
        }

        if (hasChanged) {
          intervalCounter = 15;
        }
      }
      intervalCounter++;
    }, 3000);
  }

  useEffect(() => {
    fetchUserInfo(false);
  }, [useReadUserBalance.data, useReadTotalSupply.data]);


  async function fetchLastVerified() {
    console.log('fetchLastVerified');

    if (useReadLastVerified.data === undefined) return;

    let _lastVerified = parseInt(useReadLastVerified.data.toString()) * 1000;
    let verifiedAt = null;
    let verifiedDateString = "";
    let _timeSinceLastVerify = "";

    if (_lastVerified !== undefined) {
      verifiedAt = new Date(_lastVerified);
    }

    let msSinceLastVerified = Date.now() - _lastVerified;
    _timeSinceLastVerify = secondsToTimeString(msSinceLastVerified);

    if (_lastVerified < 1) {
      verifiedDateString = "Never verified";
      _timeSinceLastVerify = null;
    } else {
      verifiedDateString = verifiedAt.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' }) + ", " + verifiedAt.toLocaleTimeString('en-US', { hour12: false, hour: "2-digit", minute: "2-digit", second: "2-digit" });
    }
    setLastVerified(verifiedDateString);
    setLastVerifiedDifference(_timeSinceLastVerify);

  }

  useEffect(() => {
    fetchLastVerified();
  }, [useReadLastVerified.data]);

  async function fetchEnrollTime() {
    console.log('fetchEnrollTime');

    if (useReadMintTime.data === undefined) return;
    if (useReadMinEnrollTime.data === undefined) return;

    let enrollTime = parseInt(useReadMintTime.data.toString()) * 1000;
    let createdAtDate = null;
    let createdDateString = "";

    let _minEnrollTime = parseInt(useReadMinEnrollTime.data.toString()) * 1000;
    let _minEnrollTimeInWords = secondsToTimeString(_minEnrollTime);
    console.log("_minEnrollTimeInWords", _minEnrollTimeInWords);

    let unenrollAllowed = enrollTime + parseInt(useReadMinEnrollTime.data.toString()) * 1000;

    if (enrollTime !== undefined) {
      createdAtDate = new Date(enrollTime);
    }

    if (enrollTime < 1) {
      createdDateString = "Never minted";
    } else {
      createdDateString = createdAtDate.toLocaleDateString('en-US', { day: '2-digit', month: 'long', year: 'numeric' });
    }

    setCreatedAt(createdDateString);
    setUnenrollAllowedTimestamp(unenrollAllowed);
    setMinEnrollTimeInWords(_minEnrollTimeInWords);

  }

  useEffect(() => {
    fetchEnrollTime();
  }, [useReadMintTime.data, useReadMinEnrollTime.data]);



  async function fetchPrices() {
    console.log('fetchPrices');

    if (useReadMintPrice.data === undefined) return;
    if (useReadVerifyPrice.data === undefined) return;
    if (useReadMinEnrollTime.data === undefined) return;

    let _verifyPriceString = BigNumber(useReadVerifyPrice.data.toString()).div(10 ** 18);

    //let _verifyPriceString = BigNumber("10000000000").div(10 ** 18);

    setPrices({ mintPrice: useReadMintPrice.data, verifyPrice: useReadVerifyPrice.data, verifyPriceString: _verifyPriceString.toString() });
    setMinEnrollTime(useReadMinEnrollTime.data);

  }

  useEffect(() => {
    fetchPrices();
  }, [useReadMintPrice.data, useReadVerifyPrice.data, useReadMinEnrollTime.data]);


  useEffect(() => {
    if (address !== undefined && address !== null) {

      if (cookies.personaSession === undefined || cookies.personaSession.length < 5 || cookies.personaSession.slice(0, 4) !== "inq_") {
        console.log("No personaSession cookie found");
      } else {
        console.log("Updated address", address);
        let personaSession = cookies.personaSession.split(":");
        console.log("Old address", personaSession[1]);
        if (address.toLowerCase() !== personaSession[1].toLowerCase()) {
          console.log("Address changed. Generating new session");
          generateNewSession();
        } else {
          fetchUserInfo();
        }
      }
    }
  }, [address]);


  async function mintToken() {
    setIsMinting(true);
    setAllButtonsDisabled(true);

    try {
      let data = {
        walletAddress: address,
        APIKey: API.KEY
      }

      let params = new URLSearchParams(data).toString();
      let url = API.URI + '/enrollment/getProof?' + params;

      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();

      if (!response.ok) {
        toast.error("Request failed - " + result.msg);
        return;
      }

      if (useReadBalance.data.value <= prices?.mintPrice) {
        //let currency = "MATIC";
        let currency = NETWORKSBYID[currentDisplayNetwork]?.nativeCurrency.symbol;
        if (useReadBalance.data.symbol !== undefined) {
          currency = useReadBalance.data.symbol;
        }
        toast.error("This transaction may fail. Make sure you have enough " + currency);
      }

      useWriteMint.write({
        args: [result.data.mintSignatureTimestamp, result.data.mintProof]
      });

    } catch (e) {
      console.error(e);
      toast.error("Minting failed");
      setAllButtonsDisabled(false);
      setIsMinting(false);
      return;
    }

  }

  async function updateVerificationOnChain(verificationSignature, verificationTimestamp) {
    setIsUpdatingVerify(true);
    setAllButtonsDisabled(true);

    try {

      useWriteVerify.write({
        args: [verificationTimestamp, verificationSignature]
      });

    } catch (e) {
      console.error(e);
      toast.error("Verification failed");
      setAllButtonsDisabled(false);
      setIsUpdatingVerify(false);
      return;
    }

  }

  async function burnToken() {
    setIsBurning(true);
    setAllButtonsDisabled(true);
    toast("Burning...");

    if (Date.now() <= unenrollAllowedTimestamp) {
      console.log("minEnrollmentTime hasn't passed.");
      toast.error("Please wait until " + minEnrollTimeInWords + " since enrollment has passed.");
      setIsBurning(false)
      setAllButtonsDisabled(false);
      return;
    }

    try {
      useWriteBurn.write();
    } catch (e) {
      console.error(e);
      toast.error("Burn failed");
      setIsBurning(false)
      setAllButtonsDisabled(false);
      return;
    }

  }

  useEffect(() => {
    if (address === undefined || address === null || personaInquiry === undefined || personaInquiry === null) {
      return;
    }

    if (personaInquiry.isPending) {

      personaClient = new Persona.Client({
        inquiryId: personaInquiry.inquiryId,
        sessionToken: personaInquiry.sessionKey,
        referenceId: address.toLowerCase(),
        environmentId: "env_yMgAwpqmRDojZNiRhUvdNV3H",
        onComplete: ({ inquiryId, status, fields }) => {
          console.log("onComplete", inquiryId);
          console.log(status, fields, isVerifying);
          if (isVerifying) {
            setIsVerified({ verified: true, inq: inquiryId });
            setIsVerifying(false);
          } else {
            toast("Please wait while we're verifying your enrollment");
            loopFetchUser(true);
          }
        },
        onCancel: () => {
          setEnrollmentButtonActive(true);
        },
        onError: (error) => console.log(error),
      });

    } else {

      personaClient = new Persona.Client({
        inquiryId: personaInquiry.inquiryId,
        referenceId: address.toLowerCase(),
        environmentId: "env_yMgAwpqmRDojZNiRhUvdNV3H",
        onComplete: ({ inquiryId, status, fields }) => {
          console.log("onComplete", inquiryId);
          console.log(status, fields, isVerifying);
          if (isVerifying) {
            setIsVerified({ verified: true, inq: inquiryId });
            setIsVerifying(false);
          } else {
            toast("Please wait while we're verifying your enrollment");
            loopFetchUser(true);
          }
        },
        onCancel: () => {
          setEnrollmentButtonActive(true);
        },
        onError: (error) => console.log(error),
      });

    }

  }, [personaInquiry, address]);

  async function generateNewSession() {
    let refId = address;

    let url = API.URI + '/inquiry/create';
    let data = {
      referenceId: refId,
      APIKey: API.KEY
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        return;
      } else {
        const result = await response.json();


        let personaSession = result.data.inquiryId + ":" + refId;
        setCookie('personaSession', personaSession, { path: '/' });
        setPersonaInquiry({ inquiryId: result.data.inquiryId, sessionKey: null, isPending: false });
        return result.data.inquiryId;
      }
    } catch (e) {
      console.error(e);
      return;
    }
  }

  async function isInquiryResumable(inquiryId) {

    let data = {
      inquiryId: inquiryId,
      APIKey: API.KEY
    }
    let params = new URLSearchParams(data).toString();
    let url = API.URI + '/inquiry/status?' + params;

    try {
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        return false;
      } else {
        const result = await response.json();
        let status = result.data.inquiryStatus.toLowerCase();
        if (status === "created" || status === "pending" || status === "expired") {
          return true;
        } else {
          return false;
        }
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async function resumeSession(inquiryId) {
    let isResumable = await isInquiryResumable(inquiryId);
    if (!isResumable) {
      generateNewSession();
      return;
    }

    let url = API.URI + '/inquiry/resume';
    let data = {
      inquiryId: inquiryId,
      APIKey: API.KEY
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        if (response.status === 409) {
          console.log("Stored session has been redacted - generating a new one...");
          generateNewSession();
        }
        return;
      } else {
        const result = await response.json();

        let personaSession = result.data.inquiryId + ":" + result.data.walletAddress;
        setCookie('personaSession', personaSession, { path: '/' });
        setPersonaInquiry({ inquiryId: result.data.inquiryId, sessionKey: result.data.sessionToken, isPending: true });

      }
    } catch (e) {
      console.error(e);
      return;
    }
  }

  useEffect(() => {
    console.log("persona session useEffect()");

    if (isDuplicate === undefined || personaStatus === undefined || isDuplicate !== false || isBurned === undefined) {
      return;
    }

    //skipping this check for now, as we need a session to verify enrollment
    //if (personaStatus !== "onboarded") {

    if (cookies.personaSession === undefined || cookies.personaSession.length < 5 || cookies.personaSession.slice(0, 4) !== "inq_") {
      //no existing session - generate a new one
      console.log("Persona session doesn't exist. Creating a new one.");

      generateNewSession();
    } else {
      //a session exists - verify if valid, then resume it
      console.log("cookies.personaSession", cookies.personaSession);
      let personaSession = cookies.personaSession.split(":");
      //if (address.toLowerCase() === personaSession[1].toLowerCase() && !isBurned) {
      if (address.toLowerCase() === personaSession[1].toLowerCase()) {
        console.log("Persona session already exists, resuming: ", personaSession[0]);
        resumeSession(personaSession[0]);
      } else {
        console.log("User address mismatch. Generating a new session.");
        generateNewSession();
      }
    }
    /*} else {
      console.log("User already onboarded");
    }*/
    //}, [cookies, isDuplicate, isBurned]);
  }, [cookies, isDuplicate]);


  async function verifyEnrollment() {
    console.log("isVerified changed", isVerified?.verified);
    if (isVerified?.verified) {

      if (useReadBalance.data.value <= prices?.verifyPrice) {
        let currency = NETWORKSBYID[currentDisplayNetwork]?.nativeCurrency.symbol;
        if (useReadBalance.data.symbol !== undefined) {
          currency = useReadBalance.data.symbol;
        }
        toast.error("The on-chain transaction may fail. Make sure you have enough " + currency);
      }

      let data = {
        inquiryId: isVerified?.inq,
        APIKey: API.KEY
      }

      let params = new URLSearchParams(data).toString();
      let url = API.URI + '/inquiry/verify?' + params;

      try {
        const response = await fetch(url, {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();

        if (result.data.exists && !response.ok) {
          toast.error("Request failed - " + result.msg);
          return;
        }
        console.log(result);
        console.log(result.data.verificationStatus);

        if (result.data.verificationStatus.toLowerCase() === "passed") {
          setVerificationDetails({ signature: result.data.verificationSignature, timestamp: result.data.verificationTimestamp });

          toast.success("Biometric verification successful. Confirming on-chain...");
          updateVerificationOnChain(result.data.verificationSignature, result.data.verificationTimestamp);
          fetchUserInfo(false);
        } else {
          toast.error("Biometric verification failed");
        }

      } catch (e) {
        console.error(e);
      }
    }

  }

  useEffect(() => {
    verifyEnrollment();

  }, [isVerified]);

  useEffect(() => {
    if (isVerifying === true) {
      personaClient.open();
    }

  }, [isVerifying]);



  function enroll() {
    if (!!personaClient) {
      personaClient.open();
      //Persona.Client.open();
      setEnrollmentButtonActive(false);
    }
  }

  async function verify() {
    if (!!personaClient) {
      toast("We're preparing your verify session...");
      let inq = await generateNewSession();
      console.log(inq);
      setIsVerifying(true);
    }
  }

  async function selectChain(chainId) {
    console.log("Switching chain to " + chainId + "...");
    switchNetwork(chainId);
  }

  function switchSettingsMenu() {
    setSettingsMenuOpen(!settingsMenuOpen);
  }

  return (
    <>
      <Navbar />

      <div className="background-elements">
        {
          isUserHolder ? (
            <img src={lines} alt="" className="lines" />
          ) : (
            <>
              <img src={ellipse} alt="" className="ellipse-1" />
              <img src={ellipse} alt="" className="ellipse-2" />
            </>

          )
        }
      </div>

      <div className="content-wrapper">
        {
          isWalletConnected ? (

            isNetworkCorrect ? (
              <>
                {
                  //<h4>Welcome, {address}!</h4>
                  //!isUserHolder ? (
                  !isUserOnboarded || (isUserOnboarded && !isUserHolder && !isBurned) ? (
                    <>
                      <h1 className="header-big">
                        Enroll and mint your MeID token
                      </h1>
                      <button className={isUserOnboarded ? "btn-setup completed" : (isDuplicate || allButtonsDisabled ? "btn-setup locked" : "btn-setup active")} onClick={enroll} disabled={allButtonsDisabled || personaInquiry === null || isUserHolder || isUserHolder === null || isUserOnboarded || !enrollmentButtonActive || isDuplicate}>
                        <span className="text-big">Enroll</span>
                        <span className="text-small">Complete your biometric enrollment to proceed</span>
                        <div className="btn-setup-icon">
                          <div className="circle"></div>
                          <img src={isUserOnboarded ? tick : (isDuplicate || allButtonsDisabled ? lock : arrow)} alt="" />
                        </div>
                      </button>
                      <button className={isUserOnboarded && !allButtonsDisabled ? "btn-setup active" : "btn-setup locked"} onClick={mintToken} disabled={allButtonsDisabled || isUserHolder || isUserHolder === null || !isUserOnboarded}>
                        <span className="text-big">Mint MeID</span>
                        <span className="text-small">Mint token to complete the setup</span>
                        <div className="btn-setup-icon">
                          <div className="circle"></div>
                          <img src={isUserOnboarded ? arrow : lock} alt="" />
                        </div>
                      </button>
                      <br />
                      {
                        isUserHolder === null || isUserOnboarded === null ? (
                          <WaitingScreen mode={0} />
                        ) : (
                          <AccountInfo isUserHolder={isUserCurrentNetworkHolder} personaStatus={personaStatus} isRedacted={isRedacted} isMessageSigned={isMessageSigned} lastVerified={lastVerified} lastVerifiedDifference={lastVerifiedDifference} isDuplicate={isDuplicate} />
                        )
                      }
                      {
                        signatureFailed ? (
                          <>
                            <p className="text-error">You need to sign the message in order to enroll. Refresh and try again.</p>
                            <br />
                          </>
                        ) : (
                          null
                        )
                      }
                    </>
                  ) : (
                    <>
                      <div className="dashboard-header">
                        <div className="dashboard-header-left">
                          <h1>Dashboard</h1>
                        </div>
                        <div className="dashboard-header-right">
                          <button className="btn-primary btn-primary-small" data-tooltip-id="verifyTooltip" data-tooltip-place="bottom" data-tooltip-content={"Proof of Liveness, verify and record timestamp on-chain. Fee: " + prices?.verifyPriceString + " ETH"} onClick={verify} disabled={allButtonsDisabled || isVerifying || personaInquiry === null || !isUserCurrentNetworkHolder || !isUserOnboarded || (requiredChain !== undefined && chain?.id !== requiredChain)}>
                            <span className="text">Verify</span>
                            <div className="btn-primary-icon">
                              <div className="circle"></div>
                              <img src={arrow} alt="" />
                            </div>
                          </button>
                          <Tooltip id="verifyTooltip" />
                          <div className="settings-wrapper">
                            <button className="btn-dropdown" onClick={switchSettingsMenu}>
                              <div className="btn-dropdown-inner">
                                <img src={gear} alt="" />
                                <span className="text">
                                  Settings
                                </span>
                              </div>
                            </button>
                            {
                              settingsMenuOpen ? (
                                <div className="settings-menu">
                                  <button className="settings-btn" onClick={() => setWarningScreenActive(true)} disabled={allButtonsDisabled || !isUserCurrentNetworkHolder || (requiredChain !== undefined && chain?.id !== requiredChain)}>
                                    Burn MeID
                                  </button>
                                  <button className="settings-btn" data-tooltip-id={isUserHolder ? "verifyTooltip" : null} data-tooltip-place={isUserHolder ? "bottom" : null} data-tooltip-content={isUserHolder ? "You still own MeID tokens. Make sure to burn them on all chains." : null} onClick={checkAndRemove} disabled={allButtonsDisabled || !isUserOnboarded || isUserHolder}>
                                    Purge biometrics
                                  </button>
                                  <Tooltip id="verifyTooltip" />
                                </div>
                              ) : null
                            }
                          </div>
                        </div>
                      </div>
                      <div className="dashboard-wrapper">
                        <div className="chain-selector">
                          <button className={currentDisplayNetwork === polygon.id ? "btn-select-chain active" : "btn-select-chain"} onClick={() => navigate('/polygon')}><img src={logoPolygon} alt="Polygon" /></button>
                          <button className={currentDisplayNetwork === polygon.id ? "btn-select-chain active" : "btn-select-chain"} onClick={() => navigate('/polygon')} style={{ display: "none" }} ><img src={logoEth} alt="Goerli" /></button>
                          <button className={currentDisplayNetwork === blast.id ? "btn-select-chain active" : "btn-select-chain"} onClick={() => navigate('/blast')} ><img src={logoBlast} alt="Blast" /></button>
                        </div>
                        <div className="dashboard-content">
                          <div className="top-section">
                            <div className="network-wrapper">
                              <img src={fullLogos[currentDisplayNetwork]} alt="" />
                              <span>{fullNames[currentDisplayNetwork]}</span>
                            </div>
                            <div className="mint-details">
                              {
                                isUserCurrentNetworkHolder ? (
                                  <img src={mintedTick} alt="" />
                                ) : null
                              }
                              <div className="right-col">
                                {
                                  isUserCurrentNetworkHolder ? (
                                    <>
                                      <span className="top-text">MeID minted on</span>
                                      <span className="bottom-text">{createdAt}</span>
                                    </>
                                  ) : (
                                    <button className="btn-primary btn-primary-small" style={{ marginLeft: "0" }} onClick={mintToken} disabled={allButtonsDisabled || isUserCurrentNetworkHolder || isUserCurrentNetworkHolder === null || !isUserOnboarded}>
                                      <span className="text">Mint</span>
                                      <div className="btn-primary-icon">
                                        <div className="circle"></div>
                                        <img src={arrow} alt="" />
                                      </div>
                                    </button>
                                  )
                                }
                                <span className="minted-total">Total MeID minted: {POETotalSupply}</span>
                              </div>
                            </div>
                          </div>
                          <br />
                          <AccountInfo isUserHolder={isUserCurrentNetworkHolder} personaStatus={personaStatus} isRedacted={isRedacted} isMessageSigned={isMessageSigned} lastVerified={lastVerified} lastVerifiedDifference={lastVerifiedDifference} isDuplicate={isDuplicate} />
                          {
                            requiredChain !== undefined && chain?.id !== requiredChain ? (
                              <div className="blurred-overlay">
                                <h2>Incorrect network selected</h2>
                                <SwitchNetwork validChains={validChains} selectedChain={currentNetwork} switchTo={requiredChain} />
                              </div>
                            ) : null
                          }
                        </div>

                      </div>

                      { /*
                      <br /><br />
                      <button className="btn-red" onClick={burnToken} disabled={allButtonsDisabled || !isUserHolder}>Burn POE Token</button>
                      <br />
                      <button className="btn-red" onClick={checkAndRemove} disabled={allButtonsDisabled || !isUserOnboarded || isUserHolder}>Purge biometrics</button>
                      <br />
                      <span className="smalltext">Burning your POE Token will revoke your access to all your POE-protected services and remove all your biometric information we hold.</span>
                      <br />
                      */}
                    </>
                  )
                }
                <br />

                {
                  currentDisplayNetwork !== currentNetwork || isLoading ? (
                    <WaitingScreen mode={0} />
                  ) : isMinting ? (
                    <WaitingScreen mode={1} />
                  ) : isBurning ? (
                    <WaitingScreen mode={1} />
                  ) : successScreenActive ? (
                    <SuccessScreen mode={0} callback={() => { setSuccessScreenActive(false) }} />
                  ) : warningScreenActive ? (
                    <WarningScreen mode={0} callback={() => { setWarningScreenActive(false) }} callbackConfirm={burnToken} burnAllowedTime={unenrollAllowedTimestamp} />
                  ) : null
                }
              </>
            ) : (
              <>
                <h2>Incorrect network selected</h2>
                <SwitchNetwork validChains={validChains} selectedChain={currentNetwork} switchTo={currentNetwork} />
              </>
            )
          ) : (
            <>
              <h2>Connect your wallet to continue</h2>
              <Web3Button icon="show" label="Connect Wallet" balance="hide" />
            </>
          )
        }
      </div >

      <Footer />
    </>
  );
}