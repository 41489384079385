import { useNetwork, useSwitchNetwork } from 'wagmi';
import arrow from '../img/arrow.svg';
import { toast } from 'react-toastify';

export function SwitchNetwork({ validChains, selectedChain, switchTo }) {

  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  let isValidChain = validChains?.includes(switchTo);
  let isCorrectChain = chain.id === selectedChain;

  console.log("selectedChain", selectedChain);
  console.log("chain.id", chain?.id);
  console.log("switchTo", switchTo);

  function switchNetworkClick() {
    console.log("validChains", validChains);
    console.log("isValidChain", isValidChain);
    console.log("isCorrectChain", isCorrectChain);
    console.log("selectedChain", selectedChain);
    console.log("chain.id", chain?.id);

    try {
      if (isValidChain) {
        if (switchTo) {
          switchNetwork(switchTo);
        } else {
          switchNetwork(validChains[0]);
        }
      } else {
        switchNetwork(validChains[0]);
      }
    } catch (e) {
      console.error(e);
      toast.error("Couldn't switch to network id " + selectedChain + ". Please make sure it's added in your wallet");
    }
  }

  return (
    <button className="btn-primary btn-primary-small" style={{ width: "240px" }} onClick={switchNetworkClick}>
      <span className="text">Switch network</span>
      <div className="btn-primary-icon">
        <div className="circle"></div>
        <img src={arrow} alt="" />
      </div>
    </button>
  )
}

