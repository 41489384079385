
export default function AccountInfo({ isUserHolder, personaStatus, isRedacted, isMessageSigned, lastVerified, lastVerifiedDifference, isDuplicate }) {

  return (

    <div className="info-block-wrapper">
      <div className="info-block">
        <div>{isUserHolder ? "You have a MeID token" : "You do not have a MeID token"}</div>
      </div>
      <div className="info-block">
        <div>Your enrollment status: <b>{personaStatus}</b></div>
      </div>
      {
        isRedacted.isRedacted ? (
          <div className="info-block">
            <div>Your biometrics were purged at: {isRedacted.redactedAt}</div>
          </div>
        ) : (
          null
        )
      }
      {
        isMessageSigned !== null ? (
          <div className="info-block">
            <div>Did user sign message: <b>{isMessageSigned ? "YES" : "NO"}</b></div>
          </div>
        ) : null
      }
      {
        lastVerified !== null ? (
          <div className="info-block">
            {
              lastVerifiedDifference ? (
                <div>Last verified: <b>{lastVerified}</b> ({lastVerifiedDifference} ago)</div>
              ) : (
                <div>Last verified: <b>{lastVerified}</b></div>
              )
            }
          </div>
        ) : null
      }
      {
        isDuplicate ? (
          <>
            <p className="text-error" style={{marginLeft: "auto", marginRight: "auto"}}>Our records report the biometrics on the connected wallet are enrolled on a different wallet already.</p>
          </>
        ) : null
      }

    </div>
  )
}

