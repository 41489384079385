import { mainnet, goerli, polygon, polygonMumbai } from 'wagmi/chains';
import { defineChain } from 'viem';

import MeIDABI from './ABI/MeID.json';

export const blast = defineChain({
  id: 81457,
  name: 'Blast',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://rpc.blast.io'] },
  },
  blockExplorers: {
    default: { name: 'Blastscan', url: 'https://blastscan.io/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 88189,
    },
  },
})

export const blastTest = defineChain({
  id: 168587773,
  name: 'Blast Test',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://sepolia.blast.io'] },
  },
  blockExplorers: {
    default: { name: 'Blastscan', url: 'https://testnet.blastscan.io/' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 756690,
    },
  },
})

export const ADDRESSES = {
  [mainnet.id]: {
    MeID: "0xCB23d7B889140C9cFf1317FcbeF9A23eDcb1D4BB",
  },
  [goerli.id]: {
    MeID: "0x388760A54b5B070A391870A1e35bcd3dB65D7bF2",
  },
  [polygonMumbai.id]: {
    MeID: "0x60622efc64f4b50b3b91d5c8168a50d9f2dfea4e",
  },
  [polygon.id]: {
    MeID: "0xE03ae6Bf63cd7F893404e097B21a2d9dE7BFF2f0",
  },
  [blast.id]: {
    MeID: "0xE03ae6Bf63cd7F893404e097B21a2d9dE7BFF2f0",
  },
  [blastTest.id]: {
    MeID: "0x9FA420818AdbD692c243B898E597bb63e6f0b268",
  }
};

export const ABIS = {
  MeID: MeIDABI,
};

export const API = {
  KEY: 'b653e95d-a6b9-4b2b-b299-1b223e39cdd8',
  //URI: 'http://localhost:1337',
  //URI: 'https://apimumbai.demos.global',
  URI: 'https://apiblast.demos.global',
}

export const NETWORKCODENAMES = {
  [goerli.id]: "GOERLI",
  [blastTest.id]: "BLASTSEPOLIA",
  [blast.id]: "BLAST",
  [polygon.id]: "POLYGON"
}

export const NETWORKSBYID = {
  [goerli.id]: goerli,
  [blastTest.id]: blastTest,
  [blast.id]: blast,
  [polygon.id]: polygon
}

export const NETWORKURLS = {
  [goerli.id]: "/polygon",
  [blastTest.id]: "/blast",
  [blast.id]: "/blast",
  [polygon.id]: "/polygon"
}

//export const NETWORKS = [polygon, blastTest];
//export const NETWORKIDS = [polygon.id, blastTest.id];

export const NETWORKS = [polygon, blast];
export const NETWORKIDS = [polygon.id, blast.id];
