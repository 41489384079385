import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { Web3Modal } from "@web3modal/react";
import { NETWORKS, NETWORKIDS } from "./config";

import './css/style.css';

import Admin from "./pages/Admin";
import Dashboard from "./pages/Dashboard";
import { useEffect } from "react";

let chains = NETWORKS;
let chainIds = NETWORKIDS;

const web3ModalProjectId = "1018788ea4549fff277a8d4fff09867a";

// Wagmi client
const { publicClient } = configureChains(chains, [
  w3mProvider({
    projectId: web3ModalProjectId
  }),
]);

const wagmiClient = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId: web3ModalProjectId, chains: chains }),
  publicClient
})

const ethereumClient = new EthereumClient(wagmiClient, chains);


function App() {

  useEffect(() => {
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
    (function () {
      var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
      g.async = true; g.src = 'https://cdn.matomo.cloud/demosglobal.matomo.cloud/container_2Cf43t4K.js'; s.parentNode.insertBefore(g, s);
    })();
  }, []);

  return (
    <WagmiConfig config={wagmiClient}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route index element={<Dashboard validChains={chainIds} />} />
            <Route path="admin" element={<Admin validChains={chainIds} />} />
            <Route path="dashboard" element={<Dashboard validChains={chainIds} />} />
            <Route path="polygon" element={<Dashboard validChains={chainIds} requiredChain={chainIds[0]} />} />
            <Route path="blast" element={<Dashboard validChains={chainIds} requiredChain={chainIds[1]} />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </BrowserRouter>
      </div>
      <ToastContainer />

      <Web3Modal
        projectId={web3ModalProjectId}
        ethereumClient={ethereumClient}
        themeMode={"dark"}
        themeColor={"blackWhite"}
        themeVariables={
          {
            "--w3m-font-family": "Jura",
            "--w3m-accent-color": "#f1b25aa8"
          }
        }
      />
    </WagmiConfig>
  );
}

export default App;
